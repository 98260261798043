import { Controller } from "@hotwired/stimulus";
import currency from "currency.js";

export default class extends Controller {
  connect() {
    this.setupMask();
  }

  setupMask() {
    this.mask = document.createElement("input");
    this.mask.type = "text";
    this.mask.className = this.element.className;
    this.mask.classList.remove("hidden");
    this.mask.classList.add("cursor-text");
    this.mask.spellcheck = false;
    this.mask.autocomplete = "off";
    this.mask.readOnly = true;

    const { unit, delimiter, precision, separator } = this.element.dataset;
    this.unit = unit;
    this.delimiter = delimiter;
    this.precision = parseInt(precision);
    this.separator = separator;

    this.element.parentNode.insertBefore(this.mask, this.element.nextSibling);
    this.element.style.display = "none";

    if (this.element.value) {
      this.mask.value = this.formatCurrency(this.element.value / 100);
    }

    this.mask.addEventListener("focus", () => this.startEditing());
    this.mask.addEventListener("input", () => this.adjustWidth());
    this.mask.addEventListener("blur", (e) => this.save(e));
    this.mask.addEventListener("keydown", (e) => this.handleKeydown(e));

    this.adjustWidth();
  }

  startEditing() {
    if (this.mask.readOnly) {
      this.mask.readOnly = false;
      this.mask.focus();
    }
  }

  adjustWidth() {
    this.mask.style.width = `${this.mask.value.length}ch`;
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.save(event);
    } else if (event.key === "Escape") {
      event.preventDefault();
      this.cancel();
    } else if (event.key === "Tab") {
      event.preventDefault();
      this.save(event);
      this.moveToNextCell(event.shiftKey);
    }
  }

  moveToNextCell(reverse = false) {
    const currentCell = this.element.closest("td");
    const currentRow = currentCell.closest("tr");
    let targetCell = reverse
      ? currentCell.previousElementSibling
      : currentCell.nextElementSibling;

    if (
      !targetCell ||
      !targetCell.querySelector('[data-controller="inline-edit"]')
    ) {
      const targetRow = reverse
        ? currentRow.previousElementSibling
        : currentRow.nextElementSibling;

      if (targetRow) {
        const editableCells = Array.from(
          targetRow.querySelectorAll("td"),
        ).filter((cell) =>
          cell.querySelector('[data-controller="inline-edit"]'),
        );

        targetCell = reverse
          ? editableCells[editableCells.length - 1]
          : editableCells[0];
      }
    }

    if (targetCell) {
      const targetRowId = targetCell.closest("tr").id;
      const cellIndex = Array.from(targetCell.parentNode.children).indexOf(
        targetCell,
      );

      // Watch for changes to the tbody
      const tbody = currentRow.closest("tbody");
      const observer = new MutationObserver((mutations) => {
        const newRow = document.getElementById(targetRowId);
        if (newRow) {
          const newCell = newRow.children[cellIndex];
          const mask = newCell?.querySelector('input[type="text"]');
          if (mask) {
            mask.readOnly = false;
            mask.select();
          }
          observer.disconnect(); // Clean up observer once we're done
        }
      });

      observer.observe(tbody, {
        childList: true,
        subtree: true,
      });
    }
  }

  save(event) {
    const val = currency(this.mask.value, { separator: this.separator }).value;
    if (val === 0) {
      this.element.value = "";
      this.mask.value = "";
    } else {
      this.element.value = Math.round(val * 100);
      this.mask.value = this.formatCurrency(val);
    }

    this.mask.readOnly = true;

    const formId = this.element.getAttribute("form");
    const form = document.getElementById(formId);

    if (form) {
      form.addEventListener(
        "turbo:submit-end",
        (submitEvent) => {
          if (!submitEvent.detail.success) {
            alert("Invalid value");
          }
        },
        { once: true },
      );

      form.requestSubmit();
    }
  }
  cancel() {
    if (this.element.value) {
      this.mask.value = this.formatCurrency(this.element.value / 100);
    } else {
      this.mask.value = "";
    }
    this.mask.readOnly = true;
    this.adjustWidth();
  }

  formatCurrency(val) {
    return currency(val, {
      symbol: this.unit,
      precision: this.precision,
      separator: this.separator,
      formatWithSymbol: true,
    }).format();
  }

  disconnect() {
    if (this.mask) {
      this.mask.remove();
    }
  }
}
