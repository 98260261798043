import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "input"];

  open(event) {
    event.preventDefault();
    this.modalTarget.style.display = "block";
    this.inputTarget.value = "x"; // Prevent autofill in Safari and possibly other browsers
    this.inputTarget.focus();
    setTimeout(() => {
      this.inputTarget.value = ""; // Clear the value after focusing
    }, 0);
  }

  close(event) {
    event.preventDefault();
    this.modalTarget.style.display = "none";
  }
}
